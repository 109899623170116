@import '@tradesolution/iceberg-styles/src/scss/_variables.scss';

html,
body {
    height: 100%;
    width: 100%;
    background-color: $Background-W000;
}

body {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 400;
}

iframe {
    border: none !important;
}


/* NAVBAR START */
.navbar {
    font-size: 14px;
    line-height: 20px;
    min-height: 50px;
    background-color: $B700;
    font-weight: 600;
    padding: 0;
}

.navbar .navbar-brand img {
    height: 50px;
    width: 189px;
}

.navbar .navbar-nav .nav-link {
    color: white;
    margin: 0;
    height: 50px;
    display: flex;
    align-items: center;
}

#report-dropdown-item {
    padding-left: 1em !important;
    padding-right: 1em !important;
}

.navbar .navbar-nav .nav-link:hover {
    color: white;
    background-color: $Base-B900;
}

.navbar .navbar-nav .nav-link:focus {
    color: white;
    background-color: $Base-B900;
}

.navbar .navbar-toggler {
    height: 50px;
    border: none;
    padding-top: 0;
    padding-bottom: 0;
}

.navbar-light .navbar-brand {
    display: flex;
    align-items: center;
    color: white;
    height: 50px;
    padding-bottom: 0;
    padding-top: 0;
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255,255,255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar .navbar-toggler:focus {
    box-shadow: none;
}

.navbar-collapse .nav-link {
    padding-right: 15px;
    padding-left: 15px;
}

/* For changing out "arrow-down"/"caret" on navn.dropdown (still missing animation and will be updated in own iceberg styles later). */
@import url('https://use.fontawesome.com/releases/v5.0.4/css/all.css');

.dropdown-toggle:after {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    content: '\f107';
    margin-left: 0.3rem;
    margin-top: 0.05rem;
}

.dropdown-toggle.show:after {
    content: '\f106';
}

.dropdown-toggle {
    display: flex;
}

.dropdown-toggle:after {
    border: none;
}

.noCaret .dropdown-toggle:after {
    display: none;
  }

/* NAVBAR END */

button.powerbi-page-navigation-pages.btn.btn-default.active {
    background-color: rgb(0, 153, 220);
    color: white;
}

.table-dark>tbody>tr:nth-child(2n + 1)>td,
.table-striped>tbody>tr:nth-child(2n + 1)>th {
    background-color: #333;
    color: #fff;
}

.table-dark>tbody>tr:nth-child(2n + 0)>td,
.table-striped>tbody>tr:nth-child(2n + 0)>th {
    background-color: #666;
    color: #fff;
}

/* TABLES START */

.table {

    vertical-align: middle !important;

    // passer på at bredde av kolonne tilpasses innhold
    tbody td.fitContent {
        width: 1%;
        white-space: nowrap;
    }
}

.blue-background {
    background-color: $B700;
}

.logo-container {
    width: 200px;
}

ul>.bootstrap-typeahead.clearfix.open {
    width: 400px;
    margin: 10px;
}

.navbar .avatar {
    --avatar-size: 30px;
    display: inline-block;
    width: var(--avatar-size);
    height: var(--avatar-size);

    line-height: var(--avatar-size);
    border-radius: 50%;

    color: $B700;
    text-align: center;
    background: #fff;
    font: normal normal bold 10px/30px Open Sans;
    letter-spacing: 0px;
    margin-top: -10px;
}

/* MOVE THIS TO THE HOME PAGE */

.startpage-main-header {
    font-size: 34px;
    font-weight: bold;
}

.product-switcher .icon-container {
    max-width: 32px !important;
}

.product-switcher .product-icon {
    border-radius: 6px;
}

.dropdown-menu .product-switcher {
    min-width: 180px;
}

.product-switcher .dropdown-menu a {
    display: block;
    padding: 5px;
}

.navbar .navbar-nav .show>.nav-link {
    color: white;
}

.form-label{
    font-family: 'Open Sans';
    font-size: 11px;
    line-height: 16px;
    font-weight: 600;
}